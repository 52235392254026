<template>
  <v-container fluid class="d-flex flex-column pa-4 h-100 overflow-hidden">
    <CommonTableStyles />
    <v-form ref="form" class="mt-2 mb-2 px-4 w-100">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedAccount"
            :items="accounts"
            item-title="fullName"
            item-value="id"
            label="Bank Account Selector"
            variant="outlined"
            density="compact"
            :loading="loadingAccounts"
            hide-details
            clearable
            data-cy="bank-account-selector-autocomplete"
            @update:model-value="fetchBankBalanceBreakdown"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-card
      v-if="bankBalanceBreakdown.length > 0"
      class="flex-grow-1 d-flex flex-column overflow-hidden w-100 table-card mt-4"
    >
      <v-card-text class="d-flex flex-column table-content pa-0">
        <v-data-table
          :headers="headers"
          :items="bankBalanceBreakdown"
          class="scrollable-table"
          :items-per-page="50"
          fixed-header
          data-cy="bank-balance-breakdown-table"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Bank Balance Breakdown</v-toolbar-title>
            </v-toolbar>
          </template>
          <template #[`item.balance`]="{ item }">
            {{ formatCurrency(item.balance) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-alert
      v-else-if="selectedAccount && fetchCompleted"
      type="info"
      class="mt-4"
    >
      No bank balance breakdown found for the selected Account.
    </v-alert>
    <v-progress-circular
      v-else-if="selectedAccount"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from '@/utils/axios';
import CommonTableStyles from '@/components/CommonTableStyles.vue';
import { Decimal } from 'decimal.js';

interface Account {
  id: number;
  number: string;
  name: string;
  fullName?: string;
}

interface BankBalanceBreakdownEntry {
  portfolioAbbreviation: string;
  portfolioName: string;
  balance: Decimal;
}

export default defineComponent({
  name: 'BankBalanceBreakdownView',
  components: {
    CommonTableStyles,
  },
  setup() {
    const route = useRoute();
    const pmCompanyId = ref<string>(route.params.pmCompanyId as string);
    const selectedAccount = ref<number | null>(null);
    const accounts = ref<Account[]>([]);
    const loadingAccounts = ref(false);
    const bankBalanceBreakdown = ref<BankBalanceBreakdownEntry[]>([]);
    const fetchCompleted = ref(false);

    const headers = [
      { title: 'Portfolio Abbreviation', key: 'portfolioAbbreviation' },
      { title: 'Portfolio Name', key: 'portfolioName' },
      { title: 'Balance', key: 'balance' },
    ];

    const fetchAccounts = async () => {
      loadingAccounts.value = true;
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/accounts`,
          {
            params: {
              type: 'Bank',
            },
          }
        );
        accounts.value = response.data.map((account: Account) => ({
          ...account,
          fullName: `${account.number} - ${account.name}`,
        }));
      } catch (error) {
        console.error('Error fetching bank accounts:', error);
      } finally {
        loadingAccounts.value = false;
      }
    };

    const fetchBankBalanceBreakdown = async () => {
      if (!selectedAccount.value) return;

      fetchCompleted.value = false;
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/bank-balance-breakdown`,
          {
            params: {
              accountId: selectedAccount.value,
            },
          }
        );
        bankBalanceBreakdown.value = response.data;
      } catch (error) {
        console.error('Error fetching bank balance breakdown:', error);
        bankBalanceBreakdown.value = [];
      } finally {
        fetchCompleted.value = true;
      }
    };

    onMounted(() => {
      fetchAccounts();
    });

    const formatCurrency = (value: string): string => {
      const numValue = parseFloat(value);
      return numValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    };

    return {
      selectedAccount,
      accounts,
      loadingAccounts,
      bankBalanceBreakdown,
      headers,
      formatCurrency,
      fetchBankBalanceBreakdown,
      fetchCompleted,
    };
  },
});
</script>
