import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ChartOfAccountsView from '../views/ChartOfAccountsView.vue';
import CreatePmCompanyView from '../views/CreatePropertyManagementCompanyView.vue';
import HomeView from '../views/HomeView.vue';
import ImportGeneralLedgerView from '../views/ImportGeneralLedgerView.vue';
import IncomeStatementView from '../views/IncomeStatementView.vue';
import LoginView from '../views/LoginView.vue';
import OwnerStatementView from '@/views/OwnerStatementView.vue';
import ImportFilesView from '../views/ImportFilesView.vue';
import LeaseLedgerView from '../views/LeaseLedgerView.vue'; // Add this import
import GeneralLedgerView from '@/views/GeneralLedgerView.vue';
import BankBalanceBreakdownView from '@/views/BankBalanceBreakdownView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/property-management-company/create',
    name: 'createPmCompany',
    component: CreatePmCompanyView,
  },
  {
    path: '/property-management-company/edit/:id',
    name: 'editPmCompany',
    component: CreatePmCompanyView,
    props: true,
  },
  {
    path: '/property-management-company/:pmCompanyId/general-ledger',
    name: 'generalLedger',
    component: GeneralLedgerView,
  },
  {
    path: '/property-management-company/:pmCompanyId/income-statement',
    name: 'incomeStatement',
    component: IncomeStatementView,
  },
  {
    path: '/property-management-company/:pmCompanyId/owner-statement',
    name: 'ownerStatement',
    component: OwnerStatementView,
  },
  {
    path: '/property-management-company/:id/chart-of-accounts',
    name: 'chartOfAccounts',
    component: ChartOfAccountsView,
    props: true,
  },
  {
    path: '/property-management-company/:id/import-general-ledger',
    name: 'importGeneralLedger',
    component: ImportGeneralLedgerView,
    props: true,
  },
  {
    path: '/property-management-company/:id/import-files',
    name: 'importFiles',
    component: ImportFilesView,
    props: true,
  },
  {
    path: '/property-management-company/:pmCompanyId/lease-ledger',
    name: 'leaseLedger',
    component: LeaseLedgerView,
  },
  {
    path: '/property-management-company/:pmCompanyId/bank-balance-breakdown',
    name: 'bankBalanceBreakdown',
    component: BankBalanceBreakdownView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = await store.dispatch('checkAuth');
  if (to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' });
  } else if (to.name === 'login' && isAuthenticated) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;
