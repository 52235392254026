<template>
  <v-container class="import-files-container">
    <v-row>
      <v-col cols="12" class="d-flex align-center mb-4">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
          data-cy="back-button"
        ></v-btn>
        <h1 class="text-h5">Import Files</h1>
      </v-col>
    </v-row>

    <v-alert
      v-if="errorMessage"
      type="error"
      dismissible
      class="mb-4"
      data-cy="import-error-message"
    >
      {{ errorMessage }}
    </v-alert>

    <!-- Vendor Selection Card -->
    <v-card class="mb-4 pa-4">
      <v-card-title class="text-h6 pb-2">Select Vendor</v-card-title>
      <v-select
        v-model="selectedVendor"
        :items="vendors"
        item-title="text"
        item-value="value"
        label="Select Vendor"
        name="vendor"
        required
        :rules="[(v) => !!v || 'Vendor is required']"
        density="compact"
        :disabled="isVendorDisabled"
        @update:modelValue="updateVendor"
        data-cy="vendor-select"
      ></v-select>
    </v-card>

    <!-- Chart of Accounts Card -->
    <FileImportCard
      v-if="selectedVendor !== ''"
      title="Chart of Accounts"
      :input-label="chartOfAccountsFileLabel"
      :accept="chartOfAccountsFileAccept"
      :multiple="false"
      data-cy-prefix="chart-of-accounts"
      :pm-company-id="pmCompanyId"
      import-endpoint="/property-management-companies/:pmCompanyId/import-chart-of-accounts"
      statistics-endpoint="/property-management-companies/:pmCompanyId/chart-of-accounts-statistics"
      :vendor="selectedVendor"
      v-model:statistics="chartOfAccountsStats"
    />

    <!-- General Ledger Card -->
    <FileImportCard
      v-if="selectedVendor !== ''"
      title="General Ledger"
      :input-label="generalLedgerFileLabel"
      :accept="generalLedgerFileAccept"
      :multiple="selectedVendor === 'propertyware'"
      data-cy-prefix="general-ledger"
      :pm-company-id="pmCompanyId"
      import-endpoint="/property-management-companies/:pmCompanyId/import-general-ledger"
      statistics-endpoint="/property-management-companies/:pmCompanyId/general-ledger-statistics"
      :vendor="selectedVendor"
      v-model:statistics="generalLedgerStats"
    />

    <!-- Money In Charges Card -->
    <FileImportCard
      v-if="selectedVendor === 'propertyware'"
      title="Money In Charges"
      input-label="Select XLS file"
      accept=".xls,.xlsx"
      :multiple="false"
      data-cy-prefix="money-in-charges"
      :pm-company-id="pmCompanyId"
      import-endpoint="/property-management-companies/:pmCompanyId/import-money-in-charges"
      statistics-endpoint="/property-management-companies/:pmCompanyId/money-in-charges-statistics"
      :vendor="selectedVendor"
      v-model:statistics="moneyInChargesStats"
    />

    <!-- Money In Payments Card -->
    <FileImportCard
      v-if="selectedVendor === 'propertyware'"
      title="Money In Payments"
      input-label="Select XLS file"
      accept=".xls,.xlsx"
      :multiple="true"
      data-cy-prefix="money-in-payments"
      :pm-company-id="pmCompanyId"
      import-endpoint="/property-management-companies/:pmCompanyId/import-money-in-payments"
      statistics-endpoint="/property-management-companies/:pmCompanyId/money-in-payments-statistics"
      :vendor="selectedVendor"
      v-model:statistics="moneyInPaymentsStats"
    />

    <!-- Leases Card -->
    <FileImportCard
      v-if="selectedVendor === 'propertyware'"
      title="Leases"
      input-label="Select XLS file"
      accept=".xls,.xlsx"
      :multiple="false"
      data-cy-prefix="leases"
      :pm-company-id="pmCompanyId"
      import-endpoint="/property-management-companies/:pmCompanyId/import-leases"
      statistics-endpoint="/property-management-companies/:pmCompanyId/leases-statistics"
      :vendor="selectedVendor"
      v-model:statistics="leasesStats"
    />

    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn
          color="blue"
          text
          @click="snackbar = false"
          data-cy="close-snackbar-button"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import axios from '@/utils/axios';
import { useRoute } from 'vue-router';
import FileImportCard from '@/components/FileImportCard.vue';

export default defineComponent({
  name: 'ImportFilesView',
  components: {
    FileImportCard,
  },
  setup() {
    const route = useRoute();
    const pmCompanyId = route.params.id as string;

    const selectedVendor = ref('');
    const vendors = [
      { text: 'AppFolio', value: 'appfolio' },
      { text: 'Propertyware', value: 'propertyware' },
    ];

    const isVendorDisabled = ref(false);

    type statisticsObject = {
      label: string;
      value: number | string;
      key: string;
    };

    const chartOfAccountsStats = ref<statisticsObject[]>([
      { label: 'Total Entries', key: 'totalEntries', value: 0 },
      { label: 'Last Entry Date', key: 'lastEntryDate', value: 'N/A' },
    ]);

    const generalLedgerStats = ref<statisticsObject[]>([
      { label: 'Total Entries', key: 'totalEntries', value: 0 },
      { label: 'Number of Properties', key: 'numberOfProperties', value: 0 },
      { label: 'Last Entry Date', key: 'lastEntryDate', value: 'N/A' },
    ]);

    const snackbar = ref(false);
    const snackbarText = ref('');

    const chartOfAccountsFileLabel = computed(() => {
      return selectedVendor.value === 'propertyware'
        ? 'Select XLS file'
        : 'Select CSV file';
    });

    const chartOfAccountsFileAccept = computed(() => {
      return selectedVendor.value === 'propertyware' ? '.xls,.xlsx' : '.csv';
    });

    const generalLedgerFileLabel = computed(() => {
      return selectedVendor.value === 'propertyware'
        ? 'Select XLSX files'
        : 'Select CSV file';
    });

    const generalLedgerFileAccept = computed(() => {
      return selectedVendor.value === 'propertyware' ? '.xlsx' : '.csv';
    });

    const moneyInChargesStats = ref([
      { label: 'Total Entries', key: 'totalEntries', value: 0 },
      { label: 'Last Import', key: 'lastImportDate', value: 'N/A' },
    ]);

    const moneyInPaymentsStats = ref([
      { label: 'Total Entries', key: 'totalEntries', value: 0 },
      { label: 'Last Import', key: 'lastImportDate', value: 'N/A' },
    ]);

    const leasesStats = ref([
      { label: 'Total Entries', key: 'totalEntries', value: 0 },
      { label: 'Last Import', key: 'lastImportDate', value: 'N/A' },
    ]);

    const showSnackbar = (text: string) => {
      snackbarText.value = text;
      snackbar.value = true;
    };

    const updateVendor = async () => {
      try {
        await axios.put(
          `/property-management-companies/${pmCompanyId}/vendor`,
          {
            vendor: selectedVendor.value,
          }
        );
        showSnackbar('Vendor updated successfully');
      } catch (error) {
        console.error('Error updating vendor:', error);
        showSnackbar('Error updating vendor. Please try again.');
      }
    };

    const fetchPmCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId}`
        );
        selectedVendor.value = response.data.vendor || '';
        isVendorDisabled.value = response.data.hasChartOfAccounts;
      } catch (error) {
        console.error('Error fetching PM company details:', error);
        showSnackbar('Error fetching company details. Please try again.');
      }
    };

    onMounted(async () => {
      await fetchPmCompanyDetails();
    });

    return {
      selectedVendor,
      vendors,
      isVendorDisabled,
      chartOfAccountsStats,
      generalLedgerStats,
      moneyInChargesStats,
      moneyInPaymentsStats,
      leasesStats,
      chartOfAccountsFileLabel,
      chartOfAccountsFileAccept,
      generalLedgerFileLabel,
      generalLedgerFileAccept,
      snackbar,
      snackbarText,
      updateVendor,
      pmCompanyId,
    };
  },
});
</script>

<style scoped>
.import-files-container {
  max-width: 800px;
  margin: 0 auto;
}
</style>
