<template>
  <v-container fluid class="d-flex flex-column pa-0 h-100 overflow-hidden">
    <CommonTableStyles />
    <v-row class="px-4 pt-2 flex-grow-0">
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          icon="mdi-arrow-left"
          variant="text"
          @click="$router.go(-1)"
          class="mr-4"
        ></v-btn>
        <v-card-title>Lease Ledger</v-card-title>
      </v-col>
    </v-row>
    <v-alert
      v-if="!hasLeases"
      type="warning"
      class="ma-4 flex-grow-0 custom-alert"
      density="comfortable"
    >
      There are no leases imported for this PM Company. Import leases to
      proceed.
    </v-alert>
    <v-row class="px-4 py-2 flex-grow-0" no-gutters>
      <v-col cols="4">
        <v-form @submit.prevent="validateAndFetch" ref="form" class="pr-4">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="property"
                :items="properties"
                label="Unit / Property"
                variant="outlined"
                density="compact"
                :rules="[(v) => !!v || 'Required']"
                hide-details
                class="input-field"
                :loading="loadingProperties"
                v-model:search-input="unitPropertySearch"
                clearable
                data-cy="unit-property-input-autocomplete"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="selectedTenantName"
                :items="tenants"
                item-title="displayName"
                item-value="tenant"
                label="Tenant"
                variant="outlined"
                density="compact"
                :rules="[(v) => !!v || 'Required']"
                hide-details
                class="input-field"
                :loading="loadingTenants"
                clearable
                data-cy="tenant-input-autocomplete"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-btn
                density="compact"
                type="submit"
                color="primary"
                block
                data-cy="fetch-lease-ledger-button"
                >Fetch</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="8">
        <v-row v-if="leaseLedger" class="ma-0">
          <v-col
            v-for="(item, index) in summaryItems"
            :key="index"
            cols="3"
            class="pa-1"
          >
            <v-card outlined class="pa-2">
              <div class="text-caption">{{ item.label }}</div>
              <div class="text-h6">{{ formatCurrency(item.value) }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-alert
      v-if="errorMessage"
      type="error"
      closable
      style="flex-grow: 0"
      class="error-alert ma-4"
      density="compact"
    >
      {{ errorMessage }}
    </v-alert>
    <v-card
      v-if="leaseLedger"
      class="flex-grow-1 d-flex flex-column overflow-hidden w-100 table-card ma-4"
    >
      <v-card-text class="d-flex flex-column table-content pa-0">
        <v-row justify="end" class="mx-5 flex-grow-0">
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <v-btn
                  icon="mdi-file-export"
                  size="x-small"
                  @click="exportToPDF"
                  v-bind="props"
                  class="text-none"
                  variant="text"
                  elevation="0"
                  data-cy="export-to-pdf-button"
                >
                </v-btn>
              </template>
              <span>Export to PDF</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="tableItems"
          class="scrollable-table"
          :items-length="tableItems.length"
          :items-per-page="-1"
          fixed-header
          density="compact"
          hide-default-footer
          disable-sort
          data-cy="lease-ledger-table"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td
                v-for="header in headers"
                :key="header.key"
                :class="{
                  'text-right': numericColumns.includes(header.key),
                  nowrap: dateColumns.includes(header.key),
                }"
              >
                {{
                  numericColumns.includes(header.key)
                    ? formatCurrency(item[header.key])
                    : dateColumns.includes(header.key)
                      ? formatDate(item[header.key])
                      : item[header.key]
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import CommonTableStyles from '@/components/CommonTableStyles.vue';
import axios from '@/utils/axios';
import { useRoute, useRouter } from 'vue-router';
import {
  leaseLedgerResponse,
  PropertiesResponse,
  LeaseLedgerEntry,
} from '@/types/types';
import { format } from 'date-fns';
import { parseISO } from 'date-fns';
import Decimal from 'decimal.js';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const formatCurrency = (value: string | null | undefined): string => {
  if (value == null || value === '') return '';
  if (typeof Number(value) != 'number') return '#ERROR';
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

const formatDate = (dateString: string): string => {
  if (!dateString) return '';

  return format(parseISO(dateString), 'MM/dd/yyyy');
};

interface Tenant {
  tenant: string;
  startDate: string;
  endDate: string;
  displayName: string;
}

export default defineComponent({
  name: 'LeaseLedgerView',
  components: {
    CommonTableStyles,
  },
  setup() {
    const router = useRouter();
    const form = ref(null);
    const property = ref('');
    const selectedTenantName = ref<string | null>(null);
    const route = useRoute();
    const pmCompanyId = computed(() => route.params.pmCompanyId as string);
    const leaseLedger = ref<leaseLedgerResponse | null>(null);
    const errorMessage = ref('');
    const numericColumns = ['charges', 'payments', 'balance'];
    const dateColumns = ['date', 'depositDate'];

    const tenants = ref<Tenant[]>([]);

    const selectedTenant = computed(() => {
      return (
        tenants.value.find((t) => t.tenant === selectedTenantName.value) || null
      );
    });

    const validateAndFetch = async () => {
      errorMessage.value = '';
      const { valid } = await (form.value as any).validate();
      if (valid && selectedTenant.value) {
        await fetchLeaseLedger();
      }
    };

    const fetchLeaseLedger = async () => {
      try {
        if (!selectedTenant.value) {
          errorMessage.value = 'Please select a tenant';
          return;
        }

        const response = await axios.get(`/lease-ledger`, {
          params: {
            pmCompanyId: pmCompanyId.value,
            startDate: selectedTenant.value.startDate,
            endDate: selectedTenant.value.endDate,
            property: property.value,
            tenant: selectedTenant.value.tenant,
          },
        });

        if (response.data.error) {
          errorMessage.value = response.data.error;
        } else {
          leaseLedger.value = response.data as leaseLedgerResponse;
        }
      } catch (error) {
        console.error('Error fetching lease ledger:', error);
        errorMessage.value = 'Error fetching lease ledger. Please try again.';
      }
    };

    const headers = ref([
      { title: 'Transaction', key: 'transaction' },
      { title: 'Date', key: 'date' },
      { title: 'Ref #', key: 'reference' },
      { title: 'Description', key: 'description' },
      { title: 'Payer Name', key: 'payerName' },
      { title: 'Deposit Date', key: 'depositDate' },
      { title: 'Charges', key: 'charges' },
      { title: 'Payments', key: 'payments' },
      { title: 'Balance', key: 'balance' },
    ]);

    const tableItems = computed((): LeaseLedgerEntry[] => {
      if (!leaseLedger.value) return [];

      return leaseLedger.value.entries;
    });

    const exportToPDF = () => {
      if (!leaseLedger.value) return;

      const doc = new jsPDF();

      // Set smaller font size
      doc.setFontSize(10);

      // Add lease name and address
      doc.text(`Lease: ${leaseLedger.value.leaseName || 'N/A'}`, 14, 15);
      doc.text(`Address: ${leaseLedger.value.leaseAddress || 'N/A'}`, 14, 20);

      // Add summary fields
      const summaryY = 30;
      summaryItems.value.forEach((item, index) => {
        doc.text(
          `${item.label}: ${formatCurrency(item.value.toString())}`,
          14,
          summaryY + index * 5
        );
      });

      // Define the table headers
      const tableColumn = headers.value.map((header) => header.title);

      // Define the table rows
      const tableRows = tableItems.value.map((item: LeaseLedgerEntry) =>
        headers.value.map((header) => {
          const key = header.key as keyof LeaseLedgerEntry;
          const value = item[key];
          if (numericColumns.includes(header.key)) {
            return formatCurrency(value?.toString());
          }
          if (dateColumns.includes(header.key)) {
            return formatDate(value?.toString() || '');
          }
          return value !== null ? value.toString() : '';
        })
      );

      const headerIndex = headers.value.findIndex(
        (header) => header.key === 'description'
      );

      // Add the table to the PDF
      autoTable(doc, {
        head: [tableColumn],
        body: tableRows,
        startY: 50, // Start table after summary
        styles: { fontSize: 7 },
        columnStyles: {
          [headerIndex]: { cellWidth: 50 }, // Wider description column
        },
        didDrawPage: (data: {
          pageNumber: number;
          pageCount: number;
          settings: { margin: { left: number } };
        }) => {
          // Add page number at the bottom
          doc.text(
            `Page ${data.pageNumber} of ${data.pageCount}`,
            data.settings.margin.left,
            doc.internal.pageSize.height - 10
          );
        },
      });

      // Save the PDF
      doc.save('lease_ledger.pdf');
    };

    const loadingProperties = ref(false);
    const unitPropertySearch = ref('');
    const properties = ref<string[]>([]);

    const loadingTenants = ref(false);

    const hasLeases = ref(true);

    const fetchProperties = async () => {
      try {
        loadingProperties.value = true;
        const response = (await axios.get(
          `/property-management-companies/${pmCompanyId.value}/properties`
        )) as PropertiesResponse;
        properties.value = Object.values(response.data.portfolios).flatMap(
          (portfolio) => portfolio.properties
        );
        hasLeases.value = properties.value.length > 0;
      } catch (error) {
        console.error('Error fetching unit properties:', error);
        errorMessage.value =
          'Error fetching unit properties. Please try again.';
        hasLeases.value = false;
      } finally {
        loadingProperties.value = false;
      }
    };

    const fetchTenants = async () => {
      if (!property.value) {
        tenants.value = [];
        return;
      }
      try {
        loadingTenants.value = true;
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/tenants`,
          {
            params: { property: property.value },
          }
        );
        tenants.value = response.data.map((tenant: Tenant) => ({
          ...tenant,
          displayName: `${tenant.startDate} - ${tenant.endDate} / ${tenant.tenant}`,
        }));
      } catch (error) {
        console.error('Error fetching tenants:', error);
        errorMessage.value = 'Error fetching tenants. Please try again.';
        tenants.value = [];
      } finally {
        loadingTenants.value = false;
      }
    };

    const summaryItems = computed(() => {
      if (!leaseLedger.value) return [];
      return [
        {
          label: `Balance as of ${formatDate(leaseLedger.value.lastDate)}`,
          value: leaseLedger.value.finalBalance,
        },
        { label: 'Prepayments', value: leaseLedger.value.prepayments },
        { label: 'Total Unpaid', value: leaseLedger.value.totalUnpaid },
        { label: 'Deposit Held', value: leaseLedger.value.depositHeld },
      ];
    });

    onMounted(() => {
      fetchProperties();
    });

    watch(property, () => {
      selectedTenantName.value = null; // Clear tenant when property changes
      fetchTenants(); // Fetch tenants immediately when property changes
    });

    return {
      form,
      property,
      selectedTenantName,
      tenants,
      errorMessage,
      validateAndFetch,
      leaseLedger,
      formatCurrency,
      formatDate,
      headers,
      tableItems,
      exportToPDF,
      loadingProperties,
      unitPropertySearch,
      loadingTenants,
      hasLeases,
      properties,
      numericColumns,
      dateColumns,
      summaryItems,
      router,
    };
  },
});
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
.header-row {
  flex-shrink: 0;
}
.v-btn {
  text-transform: none;
}
</style>
