<template>
  <v-card class="pa-4">
    <v-card-title class="text-h6 pb-2">{{ title }}</v-card-title>
    <v-row align="center" no-gutters>
      <v-col cols="9">
        <v-file-input
          v-model="file"
          :label="inputLabel"
          :accept="accept"
          :multiple="multiple"
          density="compact"
          hide-details
          :data-cy="`${dataCyPrefix}-file-input`"
        ></v-file-input>
      </v-col>
      <v-col cols="3" class="pl-2">
        <v-btn
          color="primary"
          @click="importFile"
          :disabled="!file || loading"
          block
          :data-cy="`import-${dataCyPrefix}-button`"
        >
          Import
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col
        v-for="(stat, index) in statistics"
        :key="index"
        :cols="12 / statistics.length"
      >
        <div class="text-caption">{{ stat.label }}:</div>
        <div class="text-body-2 font-weight-medium">
          {{ stat.value }}
        </div>
      </v-col>
    </v-row>
    <v-alert
      v-model="successAlert"
      type="success"
      dismissible
      class="mt-4"
      :data-cy="`${dataCyPrefix}-success-alert`"
    >
      <pre :data-cy="`${dataCyPrefix}-success-alert-text`">{{
        successAlertText
      }}</pre>
    </v-alert>
    <v-alert
      v-if="errorMessage"
      type="error"
      dismissible
      class="mt-4"
      :data-cy="`${dataCyPrefix}-error-alert`"
    >
      {{ errorMessage }}
    </v-alert>
    <v-progress-linear
      v-if="loading"
      v-model="progress"
      color="primary"
      height="25"
    >
      <strong>{{ Math.ceil(progress) }}%</strong>
    </v-progress-linear>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, computed } from 'vue';
import axios from '@/utils/axios';

export interface Statistic {
  label: string;
  key: string;
  value: string | number;
}

export default defineComponent({
  name: 'FileImportCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      default: '.csv,.xls,.xlsx',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    dataCyPrefix: {
      type: String,
      required: true,
    },
    pmCompanyId: {
      type: String,
      required: true,
    },
    importEndpoint: {
      type: String,
      required: true,
    },
    statisticsEndpoint: {
      type: String,
      required: true,
    },
    vendor: {
      type: String,
      required: true,
    },
    statistics: {
      type: Array as PropType<Statistic[]>,
      required: true,
    },
  },
  emits: ['update:statistics'],
  setup(props, { emit }) {
    const file = ref<File | File[] | null>(null);
    const successAlert = ref(false);
    const successAlertText = ref('');
    const errorMessage = ref('');
    const progress = ref(0); // Added progress state
    const loading = ref(false); // Added loading state

    const setErrorMessage = (error: unknown, defaultMessage: string) => {
      console.error(defaultMessage, error);
      if (typeof error === 'string' && error.length > 0) {
        errorMessage.value = error;
      } else if (error && typeof error === 'object' && 'response' in error) {
        const errorResponse = (error as any).response;
        if (errorResponse?.data?.error) {
          errorMessage.value = errorResponse.data.error;
        }
      } else {
        errorMessage.value = defaultMessage;
      }
    };

    const clearErrorMessage = () => {
      errorMessage.value = '';
    };

    const fullImportEndpoint = computed(() => {
      return props.importEndpoint.replace(':pmCompanyId', props.pmCompanyId);
    });

    const fullStatisticsEndpoint = computed(() => {
      return props.statisticsEndpoint.replace(
        ':pmCompanyId',
        props.pmCompanyId
      );
    });

    const importFile = async () => {
      if (!file.value) return;
      clearErrorMessage();
      loading.value = true;
      progress.value = 0;

      const formData = new FormData();
      if (Array.isArray(file.value)) {
        file.value.forEach((f) => formData.append('files', f));
      } else {
        formData.append('files', file.value);
      }
      formData.append('vendor', props.vendor);

      try {
        const response = await axios.post(fullImportEndpoint.value, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'text',
          onDownloadProgress: (progressEvent) => {
            const responseText = progressEvent.event.target.responseText;
            const events = responseText.split('\n\n');
            for (const event of events) {
              if (event.trim() === '') continue;
              const data = JSON.parse(event.replace('data: ', ''));

              if (data.progress !== undefined) {
                progress.value = data.progress; // Update progress bar
              }
              if (data.done) {
                const { importSummary } = data;
                successAlertText.value = `Import completed successfully\nTotal Entries: ${importSummary.totalEntries}\nImported Entries: ${importSummary.importedEntries}\nSkipped Entries: ${importSummary.skippedEntries}`;
                successAlert.value = true;
                fetchStatistics();
              } else if (data.error) {
                setErrorMessage(
                  data.error,
                  `Error importing ${props.title.toLowerCase()}`
                );
              }
            }
          },
        });
      } catch (error) {
        setErrorMessage(
          error,
          `Error importing ${props.title.toLowerCase()}. Please try again.`
        );
      } finally {
        loading.value = false; // Reset loading state
      }
    };

    const fetchStatistics = async () => {
      try {
        const response = await axios.get(fullStatisticsEndpoint.value);
        //update statistc values
        for (const stat of props.statistics) {
          stat.value = response.data[stat.key] || stat.value;
        }
      } catch (error) {
        console.error(
          `Error fetching ${props.title.toLowerCase()} statistics:`,
          error
        );
      }
    };

    fetchStatistics();

    return {
      file,
      successAlert,
      successAlertText,
      errorMessage,
      loading,
      progress,
      importFile,
    };
  },
});
</script>
