import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-caption" }
const _hoisted_2 = { class: "text-body-2 font-weight-medium" }
const _hoisted_3 = ["data-cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "pa-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "text-h6 pb-2" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, {
        align: "center",
        "no-gutters": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "9" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_file_input, {
                modelValue: _ctx.file,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file) = $event)),
                label: _ctx.inputLabel,
                accept: _ctx.accept,
                multiple: _ctx.multiple,
                density: "compact",
                "hide-details": "",
                "data-cy": `${_ctx.dataCyPrefix}-file-input`
              }, null, 8, ["modelValue", "label", "accept", "multiple", "data-cy"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "3",
            class: "pl-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "primary",
                onClick: _ctx.importFile,
                disabled: !_ctx.file || _ctx.loading,
                block: "",
                "data-cy": `import-${_ctx.dataCyPrefix}-button`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Import ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "data-cy"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "mt-2" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statistics, (stat, index) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              key: index,
              cols: 12 / _ctx.statistics.length
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(stat.label) + ":", 1),
                _createElementVNode("div", _hoisted_2, _toDisplayString(stat.value), 1)
              ]),
              _: 2
            }, 1032, ["cols"]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_alert, {
        modelValue: _ctx.successAlert,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.successAlert) = $event)),
        type: "success",
        dismissible: "",
        class: "mt-4",
        "data-cy": `${_ctx.dataCyPrefix}-success-alert`
      }, {
        default: _withCtx(() => [
          _createElementVNode("pre", {
            "data-cy": `${_ctx.dataCyPrefix}-success-alert-text`
          }, _toDisplayString(_ctx.successAlertText), 9, _hoisted_3)
        ]),
        _: 1
      }, 8, ["modelValue", "data-cy"]),
      (_ctx.errorMessage)
        ? (_openBlock(), _createBlock(_component_v_alert, {
            key: 0,
            type: "error",
            dismissible: "",
            class: "mt-4",
            "data-cy": `${_ctx.dataCyPrefix}-error-alert`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
            ]),
            _: 1
          }, 8, ["data-cy"]))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_v_progress_linear, {
            key: 1,
            modelValue: _ctx.progress,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.progress) = $event)),
            color: "primary",
            height: "25"
          }, {
            default: _withCtx(() => [
              _createElementVNode("strong", null, _toDisplayString(Math.ceil(_ctx.progress)) + "%", 1)
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}