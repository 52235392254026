<template>
  <v-container>
    <h1>PM Companies</h1>
    <v-btn color="primary" class="mb-4 mr-2" @click="createPmCompany">
      Create New Property Management Company
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="pmCompanys"
      class="elevation-1"
      density="compact"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-icon
              size="small"
              class="me-2"
              @click="updatePmCompany(item)"
              v-bind="props"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Company</span>
        </v-tooltip>
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-icon
              size="small"
              class="me-2"
              @click="importFiles(item)"
              v-bind="props"
              data-cy="import-files"
            >
              mdi-import
            </v-icon>
          </template>
          <span>Import Files</span>
        </v-tooltip>
        <!-- <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-icon
              size="small"
              class="me-2"
              @click="viewChartOfAccounts(item)"
              v-bind="props"
              data-cy="view-chart-of-accounts"
            >
              mdi-chart-box
            </v-icon>
          </template>
          <span>View Chart of Accounts</span>
        </v-tooltip>
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <v-icon
              size="small"
              class="me-2"
              @click="importGeneralLedger(item)"
              v-bind="props"
              data-cy="view-general-ledger"
            >
              mdi-import
            </v-icon>
          </template>
          <span>Import General Ledger</span>
        </v-tooltip> -->

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              variant="text"
              icon
              v-bind="props"
              size="small"
              data-cy="reports-menu-button"
            >
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="viewGeneralLedger(item)"
              data-cy="view-general-ledger"
            >
              <v-list-item-title>General Ledger</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="viewIncomeStatement(item)"
              data-cy="view-income-statement"
            >
              <v-list-item-title>Income Statement</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="viewOwnerStatement(item)"
              data-cy="view-owner-statement"
            >
              <v-list-item-title>Owner Statement (WIP)</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="viewLeaseLedger(item)"
              data-cy="view-lease-ledger"
            >
              <v-list-item-title>Lease Ledger</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="viewBankBalanceBreakdown(item)"
              data-cy="view-bank-balance-breakdown"
            >
              <v-list-item-title>Bank Balance Breakdown</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/utils/axios';

export default defineComponent({
  name: 'HomeView',
  setup() {
    const router = useRouter();

    const headers = ref([
      { title: 'Name', key: 'name', width: '30%' },
      { title: 'Email', key: 'email', width: '30%' },
      { title: 'Phone', key: 'phone', width: '20%' },
      { title: 'Actions', key: 'actions', sortable: false, width: '20%' },
    ]);

    const pmCompanys = ref([]);

    const fetchPmCompanys = async () => {
      try {
        const response = await axios.get('/property-management-companies');
        pmCompanys.value = response.data;
      } catch (error) {
        console.error('Error fetching pmCompanys:', error);
      }
    };

    onMounted(fetchPmCompanys);

    const createPmCompany = () => {
      router.push('/property-management-company/create');
    };

    const updatePmCompany = (item: any) => {
      router.push(`/property-management-company/edit/${item.id}`);
    };

    const importFiles = (item: { id: number }) => {
      router.push(`/property-management-company/${item.id}/import-files`);
    };

    const viewChartOfAccounts = (item: any) => {
      router.push(`/property-management-company/${item.id}/chart-of-accounts`);
    };

    const importGeneralLedger = (item: any) => {
      router.push(
        `/property-management-company/${item.id}/import-general-ledger`
      );
    };

    const viewGeneralLedger = (item: any) => {
      router.push(`/property-management-company/${item.id}/general-ledger`);
    };

    const viewIncomeStatement = (item: any) => {
      router.push(`/property-management-company/${item.id}/income-statement`);
    };

    const viewOwnerStatement = (item: any) => {
      router.push(`/property-management-company/${item.id}/owner-statement`);
    };

    const viewLeaseLedger = (item: any) => {
      router.push(`/property-management-company/${item.id}/lease-ledger`);
    };

    const viewBankBalanceBreakdown = (item: any) => {
      router.push(
        `/property-management-company/${item.id}/bank-balance-breakdown`
      );
    };

    return {
      headers,
      pmCompanys,
      createPmCompany,
      updatePmCompany,
      importFiles,
      viewChartOfAccounts,
      importGeneralLedger,
      viewGeneralLedger,
      viewIncomeStatement,
      viewOwnerStatement,
      viewLeaseLedger,
      viewBankBalanceBreakdown,
    };
  },
});
</script>
