<template>
  <v-container fluid class="d-flex flex-column pa-4 h-100 overflow-hidden">
    <CommonTableStyles />
    <v-form ref="form" class="mt-2 mb-2 px-4 w-100">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedPortfolio"
            :items="portfolios"
            label="Portfolio Selector"
            variant="outlined"
            density="compact"
            :loading="loadingPortfolios"
            hide-details
            clearable
            data-cy="portfolio-selector-autocomplete"
            @update:model-value="onPortfolioChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedAccount"
            :items="accounts"
            item-title="fullName"
            item-value="id"
            label="Account Selector"
            variant="outlined"
            density="compact"
            :loading="loadingAccounts"
            hide-details
            clearable
            data-cy="account-selector-autocomplete"
            :disabled="!selectedPortfolio"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-card
      v-if="generalLedgerEntries.length > 0"
      class="flex-grow-1 d-flex flex-column overflow-hidden w-100 table-card mt-4"
    >
      <v-card-text class="d-flex flex-column table-content pa-0">
        <v-data-table
          :headers="headers"
          :items="generalLedgerEntries"
          class="scrollable-table"
          :items-per-page="50"
          :search="search"
          fixed-header
          data-cy="general-ledger-table"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>General Ledger Entries</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                density="compact"
                data-cy="ledger-search-field"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template #[`item.date`]="{ item }">
            {{ formatDate(item.date) }}
          </template>
          <template #[`item.tenantPaymentReceivedDate`]="{ item }">
            {{ formatDate(item.tenantPaymentReceivedDate) }}
          </template>
          <template #[`item.debit`]="{ item }">
            {{ formatCurrency(item.debit) }}
          </template>
          <template #[`item.credit`]="{ item }">
            {{ formatCurrency(item.credit) }}
          </template>
          <template #[`item.balance`]="{ item }">
            {{ formatCurrency(item.balance) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-alert
      v-else-if="selectedAccount && selectedPortfolio && fetchCompleted"
      type="info"
      class="mt-4"
    >
      No General Ledger entries found for the selected Portfolio and Account.
    </v-alert>
    <v-progress-circular
      v-else-if="selectedAccount && selectedPortfolio"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';
import axios from '@/utils/axios';
import { useRoute } from 'vue-router';
import CommonTableStyles from '@/components/CommonTableStyles.vue';
import {
  VAutocomplete,
  VDataTable,
  VContainer,
  VRow,
  VCol,
  VToolbar,
  VToolbarTitle,
  VDivider,
  VSpacer,
  VTextField,
  VAlert,
} from 'vuetify/components';

interface Account {
  id: number;
  number: string;
  name: string;
  fullName?: string;
}

interface GeneralLedgerEntry {
  portfolio: string;
  property: string | null;
  date: string;
  payeePayer: string;
  type: string;
  reference: string;
  debit: string;
  credit: string;
  balance: string;
  description: string;
  uniqueId: string;
  tenantPaymentReceivedDate: string | null;
  tenantPaymentReferenceNumber: string | null;
  tenantName: string;
  portfolioAbbreviation: string;
  buildingId: string;
  buildingName: string;
  buildingAbbreviation: string;
  unitId: string;
}

export default defineComponent({
  name: 'GeneralLedgerView',
  components: {
    CommonTableStyles,
    VAutocomplete,
    VDataTable,
    VContainer,
    VRow,
    VCol,
    VToolbar,
    VToolbarTitle,
    VDivider,
    VSpacer,
    VTextField,
    VAlert,
  },
  setup() {
    const route = useRoute();
    const pmCompanyId = ref<string>(route.params.pmCompanyId as string);
    const selectedAccount = ref<number | null>(null);
    const selectedPortfolio = ref<string | null>(null);
    const accounts = ref<Account[]>([]);
    const portfolios = ref<string[]>([]);
    const loadingAccounts = ref(false);
    const loadingPortfolios = ref(false);
    const generalLedgerEntries = ref<GeneralLedgerEntry[]>([]);
    const search = ref('');
    const fetchCompleted = ref(false);

    const headers = [
      //   { title: 'Portfolio', key: 'portfolio' },
      { title: 'Property', key: 'property' },
      { title: 'Date', key: 'date' },
      { title: 'Payee/Payer', key: 'payeePayer' },
      { title: 'Type', key: 'type' },
      { title: 'Reference', key: 'reference' },
      { title: 'Debit', key: 'debit' },
      { title: 'Credit', key: 'credit' },
      { title: 'Balance', key: 'balance' },
      { title: 'Description', key: 'description' },
      { title: 'Unique ID', key: 'uniqueId' },
      {
        title: 'Tenant Payment Received Date',
        key: 'tenantPaymentReceivedDate',
      },
      {
        title: 'Tenant Payment Reference Number',
        key: 'tenantPaymentReferenceNumber',
      },
      { title: 'Tenant Name', key: 'tenantName' },
      { title: 'Portfolio Abbreviation', key: 'portfolioAbbreviation' },
      { title: 'Building ID', key: 'buildingId' },
      { title: 'Building Name', key: 'buildingName' },
      { title: 'Building Abbreviation', key: 'buildingAbbreviation' },
      { title: 'Unit ID', key: 'unitId' },
    ];

    const fetchAccounts = async (portfolio?: string) => {
      loadingAccounts.value = true;
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/accounts`,
          {
            params: {
              portfolio: portfolio,
            },
          }
        );
        accounts.value = response.data.map((account: Account) => ({
          ...account,
          fullName: `${account.number} - ${account.name}`,
        }));
      } catch (error) {
        console.error('Error fetching accounts:', error);
      } finally {
        loadingAccounts.value = false;
      }
    };

    const fetchPortfolios = async () => {
      loadingPortfolios.value = true;
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/portfolios`
        );
        portfolios.value = response.data;
      } catch (error) {
        console.error('Error fetching portfolios:', error);
      } finally {
        loadingPortfolios.value = false;
      }
    };

    const onPortfolioChange = (newPortfolio: string | null) => {
      selectedAccount.value = null;
      if (newPortfolio) {
        fetchAccounts(newPortfolio);
      } else {
        accounts.value = [];
      }
    };

    onMounted(() => {
      fetchPortfolios();
    });

    const fetchGeneralLedgerEntries = async () => {
      if (!selectedAccount.value || !selectedPortfolio.value) return;

      fetchCompleted.value = false;
      try {
        const response = await axios.get(
          `/property-management-companies/${pmCompanyId.value}/general-ledger-entries`,
          {
            params: {
              accountId: selectedAccount.value,
              portfolio: selectedPortfolio.value,
            },
          }
        );
        generalLedgerEntries.value = response.data;
      } catch (error) {
        console.error('Error fetching general ledger entries:', error);
        generalLedgerEntries.value = [];
      } finally {
        fetchCompleted.value = true;
      }
    };

    watch([selectedAccount, selectedPortfolio], () => {
      if (selectedAccount.value && selectedPortfolio.value) {
        fetchGeneralLedgerEntries();
      } else {
        generalLedgerEntries.value = [];
        fetchCompleted.value = false;
      }
    });

    const formatDate = (dateStr?: string): string => {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      return date.toLocaleDateString('en-US');
    };

    const formatCurrency = (value: string): string => {
      const numValue = parseFloat(value);
      return numValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    };

    return {
      selectedAccount,
      selectedPortfolio,
      accounts,
      portfolios,
      loadingAccounts,
      loadingPortfolios,
      generalLedgerEntries,
      headers,
      search,
      formatDate,
      formatCurrency,
      onPortfolioChange,
      fetchCompleted,
    };
  },
});
</script>

<style scoped>
/* Add any necessary styling here */
</style>
