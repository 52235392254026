import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonTableStyles = _resolveComponent("CommonTableStyles")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "d-flex flex-column pa-4 h-100 overflow-hidden"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonTableStyles),
      _createVNode(_component_v_form, {
        ref: "form",
        class: "mt-2 mb-2 px-4 w-100"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { dense: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_autocomplete, {
                    modelValue: _ctx.selectedPortfolio,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPortfolio) = $event)),
                      _ctx.onPortfolioChange
                    ],
                    items: _ctx.portfolios,
                    label: "Portfolio Selector",
                    variant: "outlined",
                    density: "compact",
                    loading: _ctx.loadingPortfolios,
                    "hide-details": "",
                    clearable: "",
                    "data-cy": "portfolio-selector-autocomplete"
                  }, null, 8, ["modelValue", "items", "loading", "onUpdate:modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_autocomplete, {
                    modelValue: _ctx.selectedAccount,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedAccount) = $event)),
                    items: _ctx.accounts,
                    "item-title": "fullName",
                    "item-value": "id",
                    label: "Account Selector",
                    variant: "outlined",
                    density: "compact",
                    loading: _ctx.loadingAccounts,
                    "hide-details": "",
                    clearable: "",
                    "data-cy": "account-selector-autocomplete",
                    disabled: !_ctx.selectedPortfolio
                  }, null, 8, ["modelValue", "items", "loading", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512),
      (_ctx.generalLedgerEntries.length > 0)
        ? (_openBlock(), _createBlock(_component_v_card, {
            key: 0,
            class: "flex-grow-1 d-flex flex-column overflow-hidden w-100 table-card mt-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, { class: "d-flex flex-column table-content pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    headers: _ctx.headers,
                    items: _ctx.generalLedgerEntries,
                    class: "scrollable-table",
                    "items-per-page": 50,
                    search: _ctx.search,
                    "fixed-header": "",
                    "data-cy": "general-ledger-table"
                  }, {
                    top: _withCtx(() => [
                      _createVNode(_component_v_toolbar, { flat: "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_toolbar_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("General Ledger Entries")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_divider, {
                            class: "mx-4",
                            inset: "",
                            vertical: ""
                          }),
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.search,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
                            "append-icon": "mdi-magnify",
                            label: "Search",
                            "single-line": "",
                            "hide-details": "",
                            density: "compact",
                            "data-cy": "ledger-search-field"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    [`item.date`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(item.date)), 1)
                    ]),
                    [`item.tenantPaymentReceivedDate`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(item.tenantPaymentReceivedDate)), 1)
                    ]),
                    [`item.debit`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatCurrency(item.debit)), 1)
                    ]),
                    [`item.credit`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatCurrency(item.credit)), 1)
                    ]),
                    [`item.balance`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatCurrency(item.balance)), 1)
                    ]),
                    _: 2
                  }, 1032, ["headers", "items", "search"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_ctx.selectedAccount && _ctx.selectedPortfolio && _ctx.fetchCompleted)
          ? (_openBlock(), _createBlock(_component_v_alert, {
              key: 1,
              type: "info",
              class: "mt-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" No General Ledger entries found for the selected Portfolio and Account. ")
              ]),
              _: 1
            }))
          : (_ctx.selectedAccount && _ctx.selectedPortfolio)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 2,
                indeterminate: "",
                color: "primary"
              }))
            : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}